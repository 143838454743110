import React from 'react'
import Layout from './src/components/Layout'


export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>{element}</Layout>
  )
}

export function onServiceWorkerUpdateReady() {
  setTimeout(function() {
    const answer = window.confirm('The Docs have been updated. Reload to display the latest version?')
    if (answer === true) window.location.reload()
  }, 3000)
}

function loadBrowser() {
  window.removeEventListener('scroll', loadBrowser)
  window.removeEventListener('click', loadBrowser)
  // mautic
  import('./src/lib/Mautic.js')
}

// hack to prevent chat from being loaded by bots and pagespeed insights
window.addEventListener('scroll', loadBrowser)
window.addEventListener('click', loadBrowser)


